/* istanbul ignore file */

// API calls
// NOTE: this is not a nextjs app router `api` directory, just a directory named `api`

import { inspect } from 'util';
import { initializeApollo } from '@/lib/apollo';
import { unstable_cache as cache } from 'next/cache';
import {
  ALL_STORE_BASIC_PRODUCTS_PAGE_ITEMS,
  MONOLITH_API_BASE,
  CACHE_KEY_ALL,
  CACHE_KEY_PRODUCT_PRICING,
  PAYMENT_SERVICE_URL,
} from '@/constants';
import logger from '@/lib/logger';
import {
  AddressInput,
  BasicProductFragment,
  BasicProductsFragment,
  CheckoutDataInput,
  CheckoutErrorFragment,
  CheckoutFragment,
  CheckoutUnprocessableErrorsFragment,
  CollectionsV2Fragment,
  CollectionsQueryErrorResultFragment,
  CreateCheckoutDocument,
  CreatePaymentIntentDocument,
  CreatePaymentIntentInput,
  CreatePaymentIntentResultFragment,
  Currency,
  DeliveryMinervaErrorsFragment,
  DeliveryOptionsFragment,
  GetCheckoutDocument,
  GetDeliveryOptionsDocument,
  GetDeliveryOptionsInput,
  InitiatePaymentDocument,
  InitiatePaymentIntentInput,
  InitiatePaymentIntentResultFragment,
  PaymentIntentErrorResultFragment,
  ProductBlankDocument,
  ProductFragment,
  ProductQueryErrorResultFragment,
  ProductVisibilityInput,
  ProductsFragment,
  ProductsQueryErrorResultFragment,
  StoreBasicProductsDocument,
  StoreCollectionsV2Document,
  StoreDataDocument,
  StoreDataFragment,
  StoreListingDocument,
  StoreProductsDocument,
  StoreQueryErrorResultFragment,
  StoreThemeDocument,
  StoreThemeFragment,
  SubmitCheckoutDocument,
  SubmitCheckoutInput,
  UpdateCheckoutDocument,
  UpdateCheckoutInput,
  UpdatePaymentIntentDocument,
  UpdatePaymentIntentInput,
  UpdatePaymentIntentResultFragment,
  VerifyAddressDocument,
  AddressVerificationFragment,
  ProductBlankFragment,
  ProductBlankQueryErrorResultFragment,
} from '@/gql';
import { isNullish } from '@/lib/utils';
import { ENABLE_ISR, revalidate } from '@/lib/next';
import { normalizePrimaryProducts } from '@/lib/product';
import { isProductFragment, isProductsFragment, isProductsQueryErrorResultFragment } from '@/lib/gql';
import { OrderStubData } from '@/types/order-stub';
import { FulfillmentRegion } from '@/types/preferences';
import { assureEndsWith, joinSlash } from '@/lib/string';
import {
  ApolloCache,
  ApolloQueryResult,
  DefaultContext,
  FetchResult,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from '@apollo/client';

// allow caching on the server (if enabled)
const enableCache: boolean = ENABLE_ISR;

/**
 * Common apollo gql query helper
 *
 * @param options Request options, as passed to the apollo client's query method
 * @returns
 */
async function gqlQuery<T = any, TVariables extends OperationVariables = OperationVariables>(
  options: QueryOptions<TVariables, T>
): Promise<ApolloQueryResult<T>> {
  const gqlClient = initializeApollo();
  return gqlClient.query(options);
}

/**
 * Common apollo gql mutate helper
 *
 * @param options Request options, as passed to the apollo client's mutate method
 * @returns
 */
async function gqlMutate<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
  TContext extends Record<string, any> = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any> // eslint-disable-line @typescript-eslint/no-unused-vars
>(options: MutationOptions<TData, TVariables, TContext>): Promise<FetchResult<TData>> {
  const gqlClient = initializeApollo();
  return gqlClient.mutate(options);
}

/**
 * Common apollo gql query helper with support for nextjs cache tagging
 *
 * @param options Request options, as passed to the apollo client's query method
 * @param tags (optional) array of tags to associate with the cache
 * @returns
 */
async function cacheTaggedGqlQuery<T = any, TVariables extends OperationVariables = OperationVariables>(
  options: QueryOptions<TVariables, T>,
  tags?: string[]
): Promise<ApolloQueryResult<T>> {
  // note: the function (gqlQuery) args are used to build the cache key,
  // so must be passed to the function returned by cache() [can't just
  // catch them in the arrow closure]
  return enableCache
    ? cache(gqlQuery, undefined, {
        tags: [...(tags || []), CACHE_KEY_ALL], // add NEXTJS_CACHE_KEY_ALL to all requests, to allow for a global big hammer purge
        revalidate,
      })(options)
    : gqlQuery(options);
}

/**
 * Get data needed for store metadata generation
 *
 * @param [storeSlug] The store to get data for, if not valid function will return undefined
 * @returns The data (see StoreDataFragment/StoreQueryErrorResultFragment in @/gql/)
 */
export async function getStoreData(storeSlug: string): Promise<StoreDataFragment | StoreQueryErrorResultFragment> {
  const results = await cacheTaggedGqlQuery(
    {
      query: StoreDataDocument,
      variables: {
        slug: storeSlug,
      },
    },
    [storeSlug]
  );

  const storeOrError = results?.data?.store;
  if (!storeOrError) {
    throw new Error('Store discovery failed with no error context.');
  }

  return storeOrError;
}

/**
 * Get store theme options
 *
 * @param [storeSlug] The store to get the theme options for, if not valid function will return undefined
 * @returns The theme data
 */
export async function getStoreTheme(storeSlug?: string): Promise<StoreThemeFragment | undefined> {
  if (storeSlug) {
    try {
      const results = await cacheTaggedGqlQuery(
        {
          query: StoreThemeDocument,
          variables: {
            slug: storeSlug,
          },
        },
        [storeSlug]
      );

      if (results?.data?.theme) {
        return results.data?.theme;
      }
    } catch (e) {
      logger.error(`Failed to retrieve store data: ${inspect(e)}`);
    }
  } else {
    logger.warn('Invalid store slug provided to getStoreTheme()');
  }

  return undefined;
}

/**
 * Get store products
 *
 * @param storeSlug The store identifier
 * @param page The request page
 * @returns The data (see ProductFragment in @/gql/)
 */
export async function getStoreProducts(
  storeSlug: string,
  page: number,
  pageSize: number,
  collection: string | undefined,
  region: FulfillmentRegion,
  currency: Currency,
  countryCode: string = 'US',
  visibility: ProductVisibilityInput | undefined = undefined
): Promise<ProductsFragment | ProductsQueryErrorResultFragment> {
  const results = await cacheTaggedGqlQuery(
    {
      query: StoreProductsDocument,
      variables: {
        slug: storeSlug,
        collection,
        page,
        region,
        currency,
        items: pageSize,
        countryCode,
        visibility,
      },
    },
    [storeSlug, CACHE_KEY_PRODUCT_PRICING]
  );

  const productsOrError = results?.data?.products;
  if (!productsOrError) {
    throw new Error('Product discovery failed with no error context.');
  }

  if (isProductsFragment(productsOrError)) {
    // normalize the products (remove nulls), converting products to an array
    // if it was null/undefined, and filtering out products with no color options
    // (i.e. an empty primaryProducts [aka color/variation] array)
    return {
      ...productsOrError,
      products:
        productsOrError.products?.map(normalizePrimaryProducts).filter((product) => product?.primaryProduct?.length) ||
        [],
    };
  }

  return productsOrError;
}

/**
 * Get store basic products
 *
 * @param storeSlug The store identifier
 * @param page The request page
 * @returns The data (see ProductsFragment in @/gql/)
 */
export async function getStoreBasicProducts(
  storeSlug: string,
  page: number,
  pageSize: number,
  collection: string | undefined,
  region: FulfillmentRegion,
  currency: Currency,
  visibility: ProductVisibilityInput | undefined = undefined,
  options: { signal?: AbortSignal } = {}
): Promise<BasicProductsFragment | ProductsQueryErrorResultFragment | 'aborted'> {
  const signal = options?.signal || undefined;

  try {
    const results = await cacheTaggedGqlQuery(
      {
        query: StoreBasicProductsDocument,
        variables: {
          slug: storeSlug,
          collection,
          page,
          region,
          currency,
          items: pageSize,
          visibility,
        },
        context: {
          fetchOptions: {
            signal,
          },
        },
      },
      [storeSlug, CACHE_KEY_PRODUCT_PRICING]
    );

    const productsOrError = results?.data?.products;
    if (!productsOrError) {
      throw new Error('Product discovery failed with no error context.');
    }

    return productsOrError;
  } catch (e) {
    if (signal?.aborted) {
      return 'aborted';
    }
    throw e;
  }
}

/** Return type of getAllStoreBasicProductsProgressive() */
export type AllStoreBasicProductsResult = BasicProductFragment[] | 'not found' | 'aborted';

/**
 *
 * @param storeSlug The slug of the store to load all basic products of
 * @param progress Optional callback to receive the FULL accumulated list of products as data is received
 * @param signal Optional signal to allow aborting of a running request
 * @returns A BasicProductFragment, 'not found', or 'aborted'
 */
export async function getAllStoreBasicProductsProgressive(
  storeSlug: string,
  region: FulfillmentRegion,
  currency: Currency,
  visibility?: ProductVisibilityInput | undefined,
  options: { signal?: AbortSignal; progress?: (products: readonly BasicProductFragment[]) => void } = {}
): Promise<AllStoreBasicProductsResult> {
  const { signal, progress } = options;

  // abort already tripped?
  if (signal?.aborted) {
    return 'aborted';
  }

  type GetPageResult = BasicProductsFragment | 'not found' | 'aborted';
  const getPage = async (page: number): Promise<GetPageResult> => {
    // abort tripped?
    if (signal?.aborted) {
      return 'aborted';
    }

    const productsOrError = await getStoreBasicProducts(
      storeSlug,
      page /* page */,
      ALL_STORE_BASIC_PRODUCTS_PAGE_ITEMS /* page size */,
      undefined /* collection */,
      region,
      currency,
      visibility,
      { signal }
    );

    // abort tripped?
    if (productsOrError === 'aborted' || signal?.aborted) {
      return 'aborted';
    }

    // validate
    if (!productsOrError || isProductsQueryErrorResultFragment(productsOrError)) {
      return 'not found';
    }

    return productsOrError;
  };

  const products: BasicProductFragment[] = [];
  const accum = (added: readonly BasicProductFragment[]) => {
    if (added?.length) {
      // accumulate
      products.push(...added);
      // report progress (passing the full collection, not just the delta)
      progress?.(products);
    }
  };

  // get the 1st page
  const page1 = await getPage(1);

  // Early out on aborted or not found
  if (page1 === 'aborted' || signal?.aborted) {
    return [];
  } else if (page1 === 'not found') {
    return 'not found';
  }

  // Record them
  accum(page1.basicProducts || []);

  // more pages?
  const totalCount = Number(page1.total_count);
  const perPage = Number(page1.per_page);
  if (perPage && perPage > 0 && totalCount && totalCount > perPage) {
    const pages = Math.ceil(totalCount / perPage);
    // kick off getting the rest of the pages in parallel
    const promises: Promise<GetPageResult>[] = [];
    for (let page = 2; page <= pages; page += 1) {
      promises.push(getPage(page));
    }

    // the requests can run in parallel, but we want to resolve them in order
    // and progressively report the products
    for (let i = 0; i < promises.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const pageN = await promises[i];

      // Early out on aborted, ignore not found page
      if (pageN === 'aborted' || signal?.aborted) {
        return [];
      } else if (pageN !== 'not found') {
        // record them
        accum(pageN.basicProducts || []);
      }
    }
  }

  return products;
}

/**
 * Get listing
 *
 * @param storeSlug The store identifier
 * @param listingSlug listing identifier
 * @returns The data (see ProductFragment in @/gql/).  Will throw on error.
 */
export async function getStoreListing(
  storeSlug: string,
  listingSlug: string,
  productId: number | undefined,
  region: FulfillmentRegion,
  currency: Currency,
  countryCode: string = 'US',
  visibility: ProductVisibilityInput | undefined = undefined
): Promise<ProductFragment | ProductQueryErrorResultFragment> {
  const results = await cacheTaggedGqlQuery(
    {
      query: StoreListingDocument,
      variables: {
        itemSlug: listingSlug,
        slug: storeSlug,
        productId,
        region,
        currency,
        countryCode,
        visibility,
      },
    },
    [storeSlug, listingSlug, CACHE_KEY_PRODUCT_PRICING]
  );

  const productOrError = results?.data?.product;
  if (!productOrError) {
    const error = `Received invalid store listing for store listing (${storeSlug}/${listingSlug}).`;
    logger.error(error);
    throw new Error(error);
  }

  if (isProductFragment(productOrError)) {
    // normalize the products (remove nulls)
    return normalizePrimaryProducts(productOrError);
  }

  // error
  return productOrError;
}

/**
 * Get collection info for a store
 *
 * @param storeSlug The slug of the store we're pulling collections on
 * @returns ionsV2Fragment or CollectionsQueryErrorResultFragment
 */
export async function getStoreCollectionsV2(
  storeSlug: string
): Promise<CollectionsV2Fragment | CollectionsQueryErrorResultFragment> {
  const results = await cacheTaggedGqlQuery(
    {
      query: StoreCollectionsV2Document,
      variables: {
        slug: storeSlug,
      },
    },
    [storeSlug]
  );

  const collectionsOrError = results?.data?.collectionsV2;
  if (!collectionsOrError) {
    throw new Error('Collection (V2) discovery failed with no error context.');
  }

  return collectionsOrError;
}

/**
 * Create checkout
 *
 * @param checkout The checkout request object
 * @returns CreateCheckoutMutation
 */
export async function createCheckout(
  checkout: CheckoutDataInput
): Promise<CheckoutFragment | CheckoutErrorFragment | undefined> {
  try {
    const results = await gqlMutate({
      mutation: CreateCheckoutDocument,
      variables: {
        checkout,
      },
    });
    if (results?.data?.createCheckout) {
      return results.data.createCheckout;
    }
  } catch (e) {
    logger.error(`Failed to create checkout record: ${inspect(e)}`);
  }
  return undefined;
}

/**
 * Update checkout
 *
 * @param checkout The checkout request object
 * @returns CheckoutFragment | CheckoutErrorFragment | undefined
 */
export async function updateCheckout(
  checkout: UpdateCheckoutInput
): Promise<CheckoutFragment | CheckoutErrorFragment | CheckoutUnprocessableErrorsFragment | undefined> {
  try {
    const results = await gqlMutate({
      mutation: UpdateCheckoutDocument,
      variables: {
        checkout,
      },
    });
    if (results?.data?.updateCheckout) {
      return results.data.updateCheckout;
    }
  } catch (e) {
    logger.error(`Failed to update checkout record: ${inspect(e)}`);
  }
  return undefined;
}

/**
 * Submit checkout
 *
 * @param checkout The checkout request object
 * @returns SubmitCheckoutMutation
 */
export async function submitCheckout(
  checkout: SubmitCheckoutInput
): Promise<CheckoutFragment | CheckoutErrorFragment | CheckoutUnprocessableErrorsFragment | undefined> {
  try {
    const results = await gqlMutate({
      mutation: SubmitCheckoutDocument,
      variables: {
        checkout,
      },
    });
    if (results?.data?.submitCheckout) {
      return results.data.submitCheckout;
    }
  } catch (e) {
    logger.error(`Failed to submit checkout: ${inspect(e)}`);
  }
  return undefined;
}

/**
 * Get checkout
 *
 * @param checkoutId The checkout record ID
 * @param storeSlug The store identifier
 * @returns GetCheckoutQuery
 */
export async function getCheckout(
  checkoutId: string,
  storeSlug: string
): Promise<CheckoutFragment | CheckoutErrorFragment | undefined> {
  try {
    const results = await gqlQuery({
      query: GetCheckoutDocument,
      variables: {
        checkoutId,
        storeSlug,
      },
      fetchPolicy: 'network-only',
    });
    if (results?.data?.getCheckout) {
      return results.data.getCheckout;
    }
  } catch (e) {
    logger.error(`Failed to retrieve checkout record: ${inspect(e)}`);
  }
  return undefined;
}

/**
 * Get delivery options
 *
 * @param getDeliveryOptionsVars: GetDeliveryOptionsInput
 * @returns GetDeliverOptionsQuery
 */
export async function getDeliveryOptions(
  getDeliveryOptionsVars: GetDeliveryOptionsInput
): Promise<DeliveryOptionsFragment | CheckoutErrorFragment | DeliveryMinervaErrorsFragment | undefined> {
  try {
    const results = await gqlQuery({
      query: GetDeliveryOptionsDocument,
      variables: { options: getDeliveryOptionsVars },
    });
    return results.data.getDeliveryOptions || undefined;
  } catch (e) {
    logger.error(`Failed to retrieve delivery options: ${e}`);
    return undefined;
  }
}

/**
 * Smary Streets address verification
 *
 * @param address: Address
 * @returns AddressVerificationResponse
 */
export async function addressVerification(address: AddressInput): Promise<AddressVerificationFragment | undefined> {
  try {
    const results = await gqlQuery({
      query: VerifyAddressDocument,
      variables: { address },
    });
    return results.data.verifyAddress || undefined;
  } catch (e) {
    logger.error(`Failed to verify address: ${e}`);
    return undefined;
  }
}

/**
 * Create payment intent
 *
 * @param paymentIntentRequest The payment intent request object
 * @returns PaymentIntent
 */
export async function createPaymentIntent(
  paymentIntentRequest: CreatePaymentIntentInput,
  options: { signal?: AbortSignal } = {}
): Promise<CreatePaymentIntentResultFragment | PaymentIntentErrorResultFragment | 'aborted' | undefined> {
  try {
    const results = await gqlMutate({
      mutation: CreatePaymentIntentDocument,
      variables: {
        paymentIntentRequest,
      },
      fetchPolicy: 'network-only',
      context: {
        fetchOptions: {
          signal: options?.signal || undefined,
        },
      },
    });
    if (results?.data?.createPaymentIntent) {
      return results.data.createPaymentIntent;
    }
  } catch (e) {
    if (options?.signal?.aborted) {
      return 'aborted';
    }
    logger.error(`Failed to create payment intent: ${e}`);
  }
  return undefined;
}

/**
 * Update payment intent
 *
 * @param paymentIntentRequest The payment intent request object
 * @returns PaymentIntent
 */
export async function updatePaymentIntent(
  paymentIntentRequest: UpdatePaymentIntentInput,
  options: { signal?: AbortSignal } = {}
): Promise<UpdatePaymentIntentResultFragment | PaymentIntentErrorResultFragment | 'aborted' | undefined> {
  try {
    const results = await gqlMutate({
      mutation: UpdatePaymentIntentDocument,
      variables: {
        paymentIntentRequest,
      },
      fetchPolicy: 'network-only',
      context: {
        fetchOptions: {
          signal: options?.signal || undefined,
        },
      },
    });
    if (results?.data?.updatePaymentIntent) {
      return results.data.updatePaymentIntent;
    }
  } catch (e) {
    if (options?.signal?.aborted) {
      return 'aborted';
    }
    logger.error(`Failed to create payment intent: ${e}`);
  }
  return undefined;
}

/**
 * Get product details
 *
 * @param productId product identifier
 * @returns The data (see ProductBlankFragment in @/gql)
 */

export async function getProductDetails(
  productId: number | undefined
): Promise<ProductBlankFragment | ProductBlankQueryErrorResultFragment | undefined> {
  if (isNullish(productId)) {
    return undefined;
  }

  const results = await gqlQuery({
    query: ProductBlankDocument,
    variables: {
      productId,
    },
  });

  return results.data.productBlank || undefined;
}

/**
 * Get payment intent
 *
 * @param payment product identifier
 * @returns Payment intent id's
 */
export async function createPayPalPaymentIntent(
  payment: InitiatePaymentIntentInput
): Promise<InitiatePaymentIntentResultFragment | PaymentIntentErrorResultFragment | undefined> {
  try {
    const results = await gqlMutate({
      mutation: InitiatePaymentDocument,
      variables: {
        paymentIntentRequest: payment,
      },
      fetchPolicy: 'network-only',
    });
    if (results.data?.initiatePaymentIntent) {
      return results.data?.initiatePaymentIntent;
    }
  } catch (err) {
    // noop
  }
  return undefined;
}

type LocalizationDetailsData = {
  buyer_locale?: string;
  buyer_currency?: string;
  buyer_region?: string;
  buyer_country_code?: string;
};
/**
 * Hit the monolith for geolocation
 * @returns The geo location based guesses at currency/fulfillment values, or undefined.
 */
export async function getLocalizationDetails(): Promise<LocalizationDetailsData | undefined> {
  try {
    // note we hit the api directly from the client (not proxied through gql) as
    // it's using the requesting ip addr for geolocation detection
    const response = await fetch(`${assureEndsWith(MONOLITH_API_BASE, '/')}v1/localization_details`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  } catch (e) {
    logger.error(`Failed to retrieve buyer localization: ${inspect(e)}`);
    return undefined;
  }
}

/**
 * requestWithRetry() from legacy store
 *
 * @param param0
 * @returns
 */
const requestWithRetry = async ({
  url,
  options = {
    method: 'GET',
  },
  requestName = '',
  retryCount = 2,
}: {
  url: string;
  options?: RequestInit;
  requestName?: string;
  retryCount?: number;
}) => {
  let retries = 0;

  let data = null;
  let error = null;

  const sleep = (ms: number) =>
    new Promise((r) => {
      setTimeout(r, ms);
    });

  while (retries < retryCount && !data) {
    if (retries > 0) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(Math.max(retries * 250, 1000));
    }
    try {
      // eslint-disable-next-line no-await-in-loop
      const res = await fetch(url, options);

      if (!res.ok) {
        // eslint-disable-next-line no-await-in-loop
        const { errors } = await res.json();
        if (res.status >= 400 && res.status <= 499 && errors && errors.length > 0) {
          error = errors;
          break;
        } else {
          error = `${requestName} Request retry exceeded limit`;
          retries += 1;
        }
      } else {
        error = null;
        // eslint-disable-next-line no-await-in-loop
        data = await res.json();
      }
    } catch (err) {
      error = new Error(`${requestName} ${(err as any)?.message}`, { cause: err });
      retries += 1;
    }
  }

  if (error) {
    throw error;
  } else {
    return data;
  }
};

/**
 * Sends the purchase events when someone places an order
 *
 * @param {string} cartId - Number identifier for the orders
 */
export async function fetchOrder(cartId: string): Promise<OrderStubData[] | undefined> {
  try {
    // There seems to be a race where the order isn't immediately available for
    // lookup, so use the retry logic which appears to be working on the legacy
    // store.
    const data = await requestWithRetry({
      url: `${assureEndsWith(MONOLITH_API_BASE, '/')}v1/orders?cartId=${cartId}`,
      requestName: 'fetchOrder',
      retryCount: 10,
      options: {
        method: 'GET',
        redirect: 'error',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    });
    if (!data) {
      throw new Error('Failed to fetch order data');
    }
    return data;
  } catch (e) {
    logger.error(`Failed to update checkout record: ${inspect(e)}`);
    return undefined;
  }
}

export async function appleVerification(domainName: string) {
  try {
    await fetch(joinSlash(PAYMENT_SERVICE_URL, 'v1/payments/verifyDomain'), {
      method: 'POST',
      body: JSON.stringify({
        domainName,
      }),
    });
  } catch (err) {
    logger.error('Apple domain registration failed', err);
  }
}
